import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../layout"
import SEO from "../components/SEO"
import FormContact from "../components/FormContact"
import SliderBlog from "../components/SliderBlog"
import GridBlog from "../components/GridBlog"
import BlogTitle from "../components/BlogTitle"

import { useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next'

export default function Index({ data, location }) {
    const {t} = useTranslation()
    const activeLanguage = React.useContext(I18nextContext).language
    const [posts, setPosts] = useState(data.pt.edges)

    useEffect(() => {
        switch (activeLanguage) {
            case "pt":
                setPosts(data.pt.edges)
                break
            case "en":
                setPosts(data.en.edges)
                break
            case "es":
                setPosts(data.es.edges)
                break
            case "fr":
                setPosts(data.fr.edges)
                break
            case "br":
                setPosts(data.br.edges)
                break
            default:
                setPosts(data.pt.edges)
                break
        }
      }, [activeLanguage, data.pt.edges, data.en.edges, data.es.edges, data.fr.edges, data.br.edges])

    return (
        <Layout 
            location={location}
            footer={t('footer', { returnObjects: true })}
            header={t('header', { returnObjects: true })}
            hideBr
        >
        <SEO title="Blog" />
        <SliderBlog id="slider-blog" data={posts} />
        <BlogTitle id="blog-title" data={t('blog', { returnObjects: true })} />
        <GridBlog id="grid-blog" data={posts} />
        {!(activeLanguage === 'br') &&
            <FormContact
                id="form-contact"
                info={t('form', { returnObjects: true })}
                lang={activeLanguage}
                location={location}
            />
        }
        </Layout>
    )
}

export const QueryBlogPT = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["blog", "form", "footer", "header"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
    pt: allMarkdownRemark(
            filter: { frontmatter: { lang: { eq: "pt" } } }
            sort: { order: DESC, fields: [frontmatter___date] }
        ) {
            edges {
                node {
                    excerpt(pruneLength: 250)
                    id
                    frontmatter {
                        title
                        date(formatString: "D[/]MM[/]YYYY")
                        path
                        lang
                        image {
                                childImageSharp {
                                fluid(quality: 100, maxWidth: 1920) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    en: allMarkdownRemark(
            filter: { frontmatter: { lang: { eq: "en" } } }
            sort: { order: DESC, fields: [frontmatter___date] }
        ) {
            edges {
                node {
                    excerpt(pruneLength: 250)
                    id
                    frontmatter {
                        title
                        date(formatString: "D[/]MM[/]YYYY")
                        path
                        lang
                        image {
                                childImageSharp {
                                fluid(quality: 100, maxWidth: 1920) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    es: allMarkdownRemark(
            filter: { frontmatter: { lang: { eq: "es" } } }
            sort: { order: DESC, fields: [frontmatter___date] }
        ) {
            edges {
                node {
                    excerpt(pruneLength: 250)
                    id
                    frontmatter {
                        title
                        date(formatString: "D[/]MM[/]YYYY")
                        path
                        lang
                        image {
                                childImageSharp {
                                fluid(quality: 100, maxWidth: 1920) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    fr: allMarkdownRemark(
            filter: { frontmatter: { lang: { eq: "fr" } } }
            sort: { order: DESC, fields: [frontmatter___date] }
        ) {
            edges {
                node {
                    excerpt(pruneLength: 250)
                    id
                    frontmatter {
                        title
                        date(formatString: "D[/]MM[/]YYYY")
                        path
                        lang
                        image {
                                childImageSharp {
                                fluid(quality: 100, maxWidth: 1920) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    br: allMarkdownRemark(
            filter: { frontmatter: { lang: { eq: "pt" } } }
            sort: { order: DESC, fields: [frontmatter___date] }
        ) {
            edges {
                node {
                    excerpt(pruneLength: 250)
                    id
                    frontmatter {
                        title
                        date(formatString: "D[/]MM[/]YYYY")
                        path
                        lang
                        image {
                                childImageSharp {
                                fluid(quality: 100, maxWidth: 1920) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
  }
`
